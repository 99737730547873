import type {IconProps} from '../types';

export function CheckmarkIcon(props: IconProps) {
  return (
    <svg
      {...props}
      viewBox="0 0 8 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.75575 0.204765C7.90026 0.341374 7.98459 0.529779 7.99022 0.728554C7.99584 0.927329 7.9223 1.1202 7.78575 1.26476L3.53575 5.76477C3.46687 5.83757 3.3841 5.89582 3.29232 5.9361C3.20055 5.97637 3.10163 5.99784 3.00142 5.99923C2.90121 6.00063 2.80173 5.98193 2.70887 5.94424C2.616 5.90654 2.53164 5.85062 2.46075 5.77976L0.210752 3.52977C0.0782723 3.38759 0.00614908 3.19954 0.0095773 3.00524C0.0130055 2.81094 0.0917175 2.62556 0.229131 2.48814C0.366544 2.35073 0.551929 2.27202 0.74623 2.26859C0.940531 2.26516 1.12858 2.33728 1.27075 2.46976L2.97575 4.17376L6.69575 0.234765C6.83236 0.0902619 7.02077 0.00592625 7.21954 0.000300543C7.41832 -0.00532516 7.61119 0.0682197 7.75575 0.204765Z"
        fill="currentColor"
      />
    </svg>
  );
}
