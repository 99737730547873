import { createSignal } from 'solid-js';
import { OneTimeAction, api, type AuthUser } from '~/modules/api';
import { CloseIcon } from '../icons';
import { Button, Checkbox, Popup } from '../ui';

const shouldShowMarketingConsentModal = (user?: AuthUser): boolean => {
  return Boolean(user
    && user?.pendingActions.includes(OneTimeAction.ANSWER_MARKETING_EMAIL_CONSENT)
    && (typeof localStorage !== 'undefined' && !localStorage?.getItem(`completedActions.${OneTimeAction.ANSWER_MARKETING_EMAIL_CONSENT}`)));
}

export function NewUserMarketingConsent(props: { user?: AuthUser }) {
  const [showModal, setShowModal] = createSignal(shouldShowMarketingConsentModal(props.user));
  const [consent, setConsent] = createSignal(false);

  const closeModal = async () => {
    setShowModal(false);
  }

  const handleConsentCheck = (checked: boolean) => {
    setConsent(checked);
  };

  const savePreference = async () => {
    try {
      setShowModal(false);

      // FIX: This is a temporary solution to prevent the user from seeing the popup again
      // This is needed because the request below is not returning a response
      localStorage?.setItem(`completedActions.${OneTimeAction.ANSWER_MARKETING_EMAIL_CONSENT}`, "done");

      await api.User.updateCommunicationPreferences({
          body: {
            marketingEmailConsent: consent(),
          },
        })
        .then(r => {
          if (r.error) {
            console.error('Error saving marketing email preference:', r.error);
            return;
          }
        });
    }
    catch (err) {
      console.error('Error saving marketing email preference:', err);
    }
  };

  return (
    <Popup
      show={showModal()}
      onClose={closeModal}
      position={{sm: 'center', base: 'bottom'}}
    >
      <div
        class="relative w-screen sm:max-w-sm bg-white rounded-t-2xl
          sm:rounded-2xl p-6 py-9 space-y-6"
      >
        <Button
          class="absolute size-6 top-4 right-4"
          variant="ghost"
          size="icon"
          onClick={closeModal}
        >
          <CloseIcon class="size-3" />
        </Button>

        <img
          class="w-64 mx-auto"
          src="/images/dreamflare-box.png"
          alt="DreamFlare Content Box"
        />

        <h3 class="df-font-beatrice font-bold text-2xl text-center">
          Welcome to DreamFlare
        </h3>

        <Checkbox
          id="consent-checkbox"
          label="Yes! Send me updates on the newest content and marketing messages via occasional
            DreamFlare Email"
          aria-label="Consent Check"
          value="consent"
          initialCheck={consent()}
          onChange={handleConsentCheck}
        />

        <Button
          class="mt-2"
          variant="primary"
          width="full"
          size="xl"
          onClick={savePreference}
        >
          Continue
        </Button>
      </div>
    </Popup>
  );
}
